<!--
 * @Author: zhouyajuan
 * @Date: 2020-10-12 15:48:16
 * @LastEditTime: 2021-01-29 10:27:37
 * @LastEditors: yangliao
 * @Description: 开票设置
 * @FilePath: /netHallOfficialAccounts/src/views/Invoice/invoiceSetting.vue
-->
<template>
    <div class="invoiceSetting-page">
        <div class="invoiceSetting-content" v-if="dataInfoFlag">
            <ul>
                <li v-for="(item, index) in dataInfo" :key="index">
                    <div class="first-li">
                        <span>{{item.wuserName}} 户号: {{item.wuserNo}}</span>
                        <p><em v-if="item.labelName && item.labelName.length > 0">{{item.labelName}}</em> {{item.wuserAddress}}</p>
                    </div>
                    <div class="center-li">
                        <span>缴费后自动开电子发票</span>
                        <van-switch v-model="item.autoInvoice" active-color="#4CD964" size="24px" @change="getAutoInvoice(index)" />
                    </div>
                    <div class="center-li">
                        <span>开票后自动发送到邮箱</span>
                        <van-switch v-model="item.autoEmail" active-color="#4CD964" size="24px" @change="getAutoEmail(index)" />
                    </div>
                    <div class="last-li" v-if="item.autoEmail">
                        <span>{{item.email}}</span>
                        <em @click="showEditMail(index)">编辑邮箱</em>
                    </div>
                </li>
            </ul>
        </div>
        <no-data v-else />

        <!-- 发送到邮箱-弹框 -->
        <van-popup v-model="show" round :closeable="false" position="bottom" :style="{ height: '60%' }" get-container="#bind">
            <div class="popup-content">
                <div class="title">
                    <p>邮箱的设置</p>
                </div>
                <div class="content">
                    <van-field
                        v-model="email"
                        placeholder="请填写您的邮箱"
                        @input="getEamilList"
                        clearable
                    />
                    <div class="list-content" v-if="showEmailList">
                        <ul>
                            <li
                                v-for="(item, index) in listContent"
                                :key="index"
                                @click="getInputValue(item)"
                            >{{item}}</li>
                        </ul>
                    </div>
                    <div class="btn">
                        <van-button type="default" class="cancelBtn" @click="show = false">取消</van-button>
                        <van-button type="info" class="sendBtn" @click="sendEmailContent" :disabled="!email">确定</van-button>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { Toast } from 'vant';
import { isEmail } from '@/utils/validator';
import { invoiceSetting, showInvoiceSetting, sendEmail } from "../../api/home";
import NoData from '@/components/noData';

export default {
    name: 'InvoiceSetting',
    components: {
        NoData
    },
    data() {
        return {
            show: false,
            dataInfo: [],
            custWaterUserRelId: '',
            autoEmail:'',
            autoInvoice: '',
            email: '',
            listContent: [],
            showEmailList: true,
            currentIndex: 0,
            dataInfoFlag: true
        }
    },
    mounted() {
        this.showInvoiceSetting();
    },
    methods: {
        async showInvoiceSetting () {
            const {status, resultData} = await showInvoiceSetting();
            if (status === 'complete') {
                if (resultData && resultData.length > 0) {
                    this.dataInfo = resultData;
                    this.dataInfoFlag = true
                } else {
                    this.dataInfoFlag = false
                }
            }
        },
        getAutoInvoice(index) {
            this.custWaterUserRelId = this.dataInfo[index].custWaterUserRelId
            this.autoEmail = this.dataInfo[index].autoEmail
            this.autoInvoice = this.dataInfo[index].autoInvoice
            this.email = this.dataInfo[index].email
            this.invoiceSetting();
        },
        getAutoEmail(index) {
            this.custWaterUserRelId = this.dataInfo[index].custWaterUserRelId
            this.autoEmail = this.dataInfo[index].autoEmail
            this.autoInvoice = this.dataInfo[index].autoInvoice
            this.email = this.dataInfo[index].email
            this.invoiceSetting();
        },
        // 开票设置
        async invoiceSetting() {
            const arg = {
                custWaterUserRelId: this.custWaterUserRelId,
                autoEmail: this.autoEmail,
                autoInvoice: this.autoInvoice,
                email: this.email
            }
            const {status, resultData} = await invoiceSetting(arg);
            if (status === 'complete') {
                this.showInvoiceSetting();
            }
        },
        showEditMail(index) {
            this.show = true
            this.currentIndex = index;
            this.custWaterUserRelId = this.dataInfo[index].custWaterUserRelId
            this.autoEmail = this.dataInfo[index].autoEmail
            this.autoInvoice = this.dataInfo[index].autoInvoice
            this.email = this.dataInfo[index].email
        },
        // 邮箱校验
        validatorEmail(val) {
            return isEmail(val);
        },
        sendEmailContent() {
            if (this.validatorEmail(this.email)) {
                this.invoiceSetting();
                this.show = false;
            } else {
                Toast('请输入正确的邮箱地址！')
            }
        },
        // 获取邮箱列表
        getEamilList(event) {
            this.listContent = []
            if (event.indexOf('@qq') >= 0) {
                this.listContent.push(`${event}@163.com`, `${event}@126.com`, `${event}@sohu.com`);
            } else if (event.indexOf('@163') >= 0) {
               this.listContent.push(`${event}@qq.com`, `${event}@126.com`, `${event}@sohu.com`);
            } else if (event.indexOf('@126') >= 0) {
                this.listContent.push(`${event}@qq.com`, `${event}@163.com`, `${event}@sohu.com`);
            } else if (event.indexOf('@sohu') >= 0) {
                this.listContent.push(`${event}@qq.com`, `${event}@163.com`, `${event}@126.com`);
            } else if (event.indexOf('@') >= 0){
                this.listContent = []
            } else {
                this.listContent.push(`${event}@qq.com`, `${event}@163.com`, `${event}@126.com`, `${event}@sohu.com`);
            }
            this.showEmailList = true
        },
        // 点击列表获取当前选中的邮箱
        getInputValue(value) {
            // this.dataInfo[this.currentIndex].email = value;
            this.email = value;
            this.showEmailList = false
        }
    }
}
</script>

<style lang="less" scoped>
.invoiceSetting-page {
    .invoiceSetting-content {
        margin: 16px;
        ul {
            li {
                background: #ffffff;
                padding-left: 16px;
                padding-right: 16px;
                padding-top: 15px;
                margin-bottom: 15px;
                border-radius: 8px;
                .first-li {
                    position: relative;
                    span {
                        font-family: PingFangSC-Medium;
                        font-size: 16px;
                        color: rgba(0,0,0,0.85);
                        letter-spacing: 0;
                        line-height: 20px;
                        font-weight: 600;

                    }
                    em {
                        font-size: 12px;
                        padding: 1px 5px;
                        font-style: normal;
                        font-family: PingFangSC-Regular;
                        color: #637FB7;
                        letter-spacing: 0;
                        line-height: 16px;
                        background: #ECF1FE;
                        border-radius: 2px;
                    }
                    p {
                        margin: 0px;
                        padding-top: 8px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular;
                        color: rgba(0,0,0,0.65);
                        letter-spacing: 0;
                        line-height: 22px;
                        padding-bottom: 8px;
                    }
                }
                .first-li::after {
                    position: absolute;
                    box-sizing: border-box;
                    content: ' ';
                    pointer-events: none;
                    right: 0px;
                    bottom: 0;
                    left: 0px;
                    border-bottom: 1px solid #ebedf0;
                    -webkit-transform: scaleY(.5);
                    transform: scaleY(.5);
                }
                .center-li {
                    display: flex;
                    justify-content: space-between;
                    height: 50px;
                    line-height: 50px;
                    position: relative;
                    span {
                        font-family: PingFangSC-Regular;
                        font-size: 14px;
                        color: rgba(0,0,0,0.85);
                        letter-spacing: 0;
                    }
                    .van-switch {
                        margin-top: 9px;
                    }
                }
                .center-li::after{
                    position: absolute;
                    box-sizing: border-box;
                    content: ' ';
                    pointer-events: none;
                    right: 0px;
                    bottom: 0;
                    left: 0px;
                    border-bottom: 1px solid #ebedf0;
                    -webkit-transform: scaleY(.5);
                    transform: scaleY(.5);
                }
                .last-li {
                    display: flex;
                    justify-content: space-between;
                    padding-top: 13px;
                    padding-bottom: 13px;
                    span {
                        font-family: PingFangSC-Regular;
                        font-size: 14px;
                        color: rgba(0,0,0,0.65);
                        letter-spacing: 0;
                    }
                    em {
                        font-style: normal;
                        font-family: PingFangSC-Regular;
                        font-size: 14px;
                        color: #4285F4;
                        letter-spacing: 0;
                        text-align: right;
                    }
                }
            }
        }
    }
    .popup-content {
        .title {
            border-bottom: 1px solid #eeeeee;
            padding-top: 12px;
            padding-bottom: 12px;
            p {
                font-family: PingFangSC-Medium;
                font-size: 18px;
                color: #333333;
                text-align: center;
                line-height: 26px;
                margin: 0px;
            }
        }
        .content {
            /deep/.van-field__control {
                height: 45px;
                background-color: #F9F8F8;
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: rgba(0,0,0,0.65);
                letter-spacing: 0;
                text-indent: 1em;
            }
            .list-content {
                ul {
                    padding-left: 25px;
                    padding-right: 25px;
                    li {
                        height: 28px;
                        line-height: 28px;
                        font-size: 14px;
                        color: rgba(0,0,0,0.65);
                    }
                }
            }
            .btn {
                margin-left: 16px;
                margin-right: 16px;
                margin-top: 30px;
                display: flex;
                justify-content: space-between;
                .cancelBtn {
                    width: 125px;
                    height: 44px;
                    border-radius: 4px;
                    background: #F6F6F6;
                    color: #4285F4;
                }
                .sendBtn {
                    width: 125px;
                    height: 44px;
                    border-radius: 4px;
                }
            }
        }
    }
}
</style>
